import { render, staticRenderFns } from "./LoadingOverlay.vue?vue&type=template&id=c15dfbba&scoped=true&"
import script from "./LoadingOverlay.vue?vue&type=script&lang=js&"
export * from "./LoadingOverlay.vue?vue&type=script&lang=js&"
import style0 from "./LoadingOverlay.vue?vue&type=style&index=0&id=c15dfbba&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c15dfbba",
  null
  
)

export default component.exports